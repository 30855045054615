import React from "react";
import Navbar from "../../Components/Navbar/index";
import "./style.css";
import PartnerInfos from "../../Components/ParterInfos";
import { useEffect, useState } from "react";
import { getUserId } from "../../Services/auth";
import faradayApi from "../../Services/faradayApi";
import merxLogo from "../../Assets/merx-logo.png";
import CompanyInfos from "../../Components/CompanyInfos/index";
import { useHistory } from "react-router-dom";

export default function AreaAdm() {
  const history = useHistory();
  const [nome, setNome] = useState("");
  const [role, setRole] = useState("");
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const id = getUserId();
        var response = await faradayApi.get(`users/${id}`);

        setNome(response.data.name);
        setRole(response.data.role);

        if (response.data.role !== "ADMIN") {
          history.push("/");
        }
      } catch (e) {
        console.log("ERRO");
        //logout();
        //window.location.reload();
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getCompanies() {
      try {
        var response = await faradayApi.get(`companies/select/get-all`);
        setCompanies(response.data);
      } catch (e) {}
    }
    getCompanies();
  }, []);

  return (
    <div className="admPage">
      <Navbar />
      <section className="infosAdm container">
        <h2 className="titleAdm">
          Área do Admin <b>MERX</b> | prime
        </h2>
        <div className="btnsAdm">
          <a href="/simulacao">
            <button>Simule a economia no mercado livre</button>
          </a>
          <a href="pesquisa-mercado-livre">
            {" "}
            <button>Pesquisa no mercado livre</button>
          </a>
        </div>
        <PartnerInfos
          cnpj={""}
          inicioLivre={""}
          logo={merxLogo}
          nome={nome}
          parterInfosTotal={""}
          sigla={role}
        />

        <CompanyInfos companies={companies} />
      </section>
    </div>
  );
}
