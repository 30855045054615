import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar/index";
import Footer from "../Components/Footer/index";
import "./style.css";
import iconsearch from "../Assets/iconsearch.svg";
import _ from "lodash";
import { getToken, getUserId } from "../Services/auth";
import { Container, Spinner } from "react-bootstrap";
import faradayApi from "../Services/faradayApi";
import { teslaApi } from "../Services/teslaApi";
import { useHistory } from "react-router-dom";

const Main = styled.div``;

const ContainerArea = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 50px;

  h1 {
    font-weight: 300;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .separate {
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .text-secondary {
    color: #0072ce !important;
    height: 2.5rem;
    width: 2.5rem;
  }
`;

const LogoArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .back {
    width: 20px;
    height: auto;
    cursor: pointer;
  }

  .back-area {
    cursor: pointer;
  }

  .logo {
    height: 100px;
  }

  span {
    font-size: 16px;
    color: #0072ce;
    text-transform: uppercase;
    margin: 0 40px 0 5px;
  }
`;
const SearchArea = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 76px;
    font-weight: 300;
    font-size: 42px;
    color: #212322;
  }

  p {
    color: #7e7e82;
    margin-bottom: 30px;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 32px;
      color: #212322;
    }
    p {
      font-size: 16px;
    }
  }
`;

const SearchBarContainer = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 5px;
`;

const Filtros = styled.span`
  display: inline-block;
  width: 50%;
`;

const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 20em;
`;

const SearchInput = styled.input`
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: white;
  width: 100%;
  outline: none;
  background: transparent;
  color: #0367c7;

  &::placeholder {
    color: #0367c7;
    text-transform: uppercase;
  }
`;

const SearchInputContainer = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  position: relative;
  // left: 20%;
  margin: auto;
  border: 1px solid #0072ce;
  padding: 13px 30px;
`;

const AgentResultContainer = styled.div`
  width: 100%;
`;

const AgentResult = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: white;
  // &:hover {
  //   background-color: lightblue;
  // }
`;
const AgentNamePlusIcon = styled.span`
  display: flex;
  align-items: center;
`;

const AgentName = styled.span`
  color: #0072ce;
  padding-left: 1em;
`;

const NotFoundBox = styled.div`
  align-items: center;
  font-family: "Roboto", sans-serif;
  text-align: center;
  padding: 1em;
  margin: 0.5em;
  border-radius: 5px;
  background-color: white;

  h4 {
    font-weight: 300;
    font-size: 20px;
  }
`;

const NotFoundHeader1 = styled.h2`
  font-size: 26px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
`;

const NotFoundSearchTerm = styled.span`
  color: #0072ce;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  font-style: italic;
`;

function formatCNPJ(cnpj: string) {
  // Remove qualquer caractere que não seja número
  cnpj = cnpj.replace(/\D/g, "");

  // Aplica a formatação com pontos e hífen
  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
}

function GenerateAgentResults(
  agents: {
    codAgente: number;
    name: string;
    sigla: string;
    cnpj: string;
  }[],
  searchPerformed: boolean,
  searchTerm: string
) {
  if (!searchPerformed && searchTerm.length > 1) {
    agents = [];
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <ContainerArea>
          <Spinner
            className="text-secondary"
            animation="border"
            variant="secondary"
          />
        </ContainerArea>
      </div>
    );
  }

  if (agents.length > 0 && searchTerm.length > 1) {
    return (
      <ContainerArea className="align">
        <h1>Resultado</h1>
        <div className="separate">
          {agents.map((agent) => (
            <AgentResult onClick={() => {}}>
              <div className="cadastros-item-search">
                <div className="cadastros-item-info">
                  <div className="cadastros-item2">
                    <strong>{agent.name}</strong>
                  </div>
                  <div className="cadastros-item3">
                    {formatCNPJ(agent.cnpj)}
                  </div>
                </div>
                <div className="cadastros-item4">
                  <Link to={`/empresa/`}>
                    <Link to={`/agente/${agent.codAgente}`}>
                      <span>VER</span>
                    </Link>
                  </Link>
                </div>
              </div>
            </AgentResult>
          ))}
        </div>
      </ContainerArea>
    );
  }

  if (searchPerformed && searchTerm.length > 1) {
    return (
      <NotFoundBox>
        <NotFoundHeader1>
          Não encontramos{" "}
          <NotFoundSearchTerm>"{searchTerm}"</NotFoundSearchTerm> na nossa base
          de dados
        </NotFoundHeader1>
        <img
          width={140}
          src={require("../Assets/404.png")}
          alt="not found icon"
        />
        <h4>Experimente alterar os termos da busca</h4>
      </NotFoundBox>
    );
  }

  return null;
}

function AgentSearch() {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [focusColor, setFocusColor] = useState("#0367c7");
  const [agentData, setAgentData] = useState<
    { codAgente: number; name: string; sigla: string; cnpj: string }[]
  >([]);

  async function searchAgents(input: string) {
    if (searchTerm !== "") {
      const response = await teslaApi.get(
        `market/term?query=${input.toUpperCase()}`
      );
      const responseData = await response.data;

      setAgentData(responseData);
      setSearchPerformed(true);
    }
  }

  //Search on input change, at least 3 chars, debounced 500 ms
  function handleInputChange(input: string) {
    const searchAgentsDebounced = _.debounce((i) => {
      searchAgents(i);
    }, 500);
    setSearchTerm(input);
    if (input.length > 1) {
      searchAgentsDebounced(input);
    }
  }

  function backPage() {
    window.history.back();
  }

  useEffect(() => {
    async function fetchData() {
      const id = getUserId();
      let response;

      try {
        response = await faradayApi.get(`users/${id}`);
      } catch (err) {}

      const permissions = response?.data?.permissions?.split(",") ?? [];

      const hasPermission =
        permissions.includes("research") || permissions.includes("prime_black");

      if (!hasPermission && response?.data?.role !== "ADMIN") {
        history.push("/");
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      <Navbar changeColor="true" />
      <Container>
        {/* <LogoArea>
          <img
            className="back"
            src={arrowleft}
            alt="Voltar"
            onClick={backPage}
          />
          <div className="back-area" onClick={backPage}>
            <span>Voltar</span>
            {!loading && (
              <>
                {role === "PARCEIRO" && (
                  <img className="logo" src={partnerLogo} alt="Logo empresa" />
                )}
                {role === "CLIENTE" && (
                  <img className="logo" src={clientLogo} alt="Logo empresa" />
                )}
              </>
            )}
          </div>
        </LogoArea> */}

        <SearchArea>
          <h1>Pesquisa de mercado livre</h1>
          <p>Busque e veja detalhes de clientes</p>
        </SearchArea>

        <SearchBarContainer>
          <SearchInputContainer style={{ borderBottomColor: focusColor }}>
            <SearchInput
              onChange={(e) => {
                handleInputChange(e.target.value);
              }}
              onFocus={() => {
                setFocusColor("#0367c7");
              }}
              onBlur={() => {
                setFocusColor("#0367c7");
              }}
              className="text"
              placeholder="DIGITE O NOME OU CNPJ DA EMPRESA (APENAS NÚMEROS)"
            ></SearchInput>
            <img
              style={{ marginRight: "-10px" }}
              src={iconsearch}
              alt="Buscar"
            />
          </SearchInputContainer>

          <Filtros>{/* FILTROS */}</Filtros>
        </SearchBarContainer>
        <MainContent>
          <AgentResultContainer>
            {GenerateAgentResults(agentData, searchPerformed, searchTerm)}
          </AgentResultContainer>
        </MainContent>
      </Container>
      <Footer />
    </Main>
  );
}

export default AgentSearch;
