import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./style.css";

import {
  isAuthenticated,
  logout,
  getToken,
  getRefreshToken,
  updateAccessToken,
  getUserId,
  getRole,
} from "../../Services/auth";

import BlackLogo from "../../Assets/blackLogo.png";

import Logo from "../../Assets/logo.png";

import faradayApi from "../../Services/faradayApi";

class App extends Component {
  constructor(props) {
    super();
    var changeColor = props.changeColor;
    var colorBack = "#0067CC";
    var fontColor = "#FFFFFF";
    var setColor;

    switch (changeColor) {
      case "true":
        colorBack = "#0067CC"; // Blue
        fontColor = "#FFFFFF"; // White
        setColor = true;
        break;
      case "false":
        colorBack = "#FFFFFF;"; // White
        fontColor = "#646464"; // Black
        setColor = false;
        break;
      default:
        console.log("");
    }

    this.state = {
      colorBack,
      fontColor,
      changeColor: setColor,
      name: "Merx",
      Usertype: "cliente",
    };
  }

  loginModalRef = (obj) => {
    this.showModal = obj && obj.handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  onLogoutClick = () => {
    logout();
    window.location.href = "/";
  };

  getFirstName = (fullName) => {
    var firstName = "";
    for (let index = 0; index < fullName.length; index++) {
      if (fullName[index] === " ") {
        return firstName;
      }
      firstName = firstName + fullName[index];
    }
    return firstName;
  };

  async componentDidMount() {
    if (isAuthenticated()) {
      const id = getUserId();
      const role = getRole();

      this.setState({ Usertype: role });

      try {
        var response = await faradayApi.get(`users/${id}`);

        const fullName = response.data.name;
        var firstName = this.getFirstName(fullName);

        this.setState({ name: firstName });
        this.setState({ Usertype: response.data.role });
      } catch (err) {
        if (err.response && err.response.status >= 400) {
          const refreshToken = getRefreshToken();
          try {
            const responseRefreshToken = await updateAccessToken(
              refreshToken,
              id
            );

            if (responseRefreshToken.status === 201) {
              window.location.reload();
            }
          } catch (refreshErr) {
            logout();
            window.location.reload();
          }
        }
      }
    } else {
      logout();
    }
  }

  render() {
    const divStyle = {
      backgroundColor: this.state.colorBack,
      color: this.state.fontColor,
      borderColor: this.state.fontColor,
    };

    //On click scrool to bottom bar
    const handleScroll = () => {
      window.scrollTo({
        top: 20000,
        behavior: "smooth",
      });
    };

    return (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bg-blue"
          style={divStyle}
          variant="light"
        >
          <Navbar.Brand href="/">
            {this.state.changeColor && (
              <img className="logoBrand white" src={Logo} alt="Logo" />
            )}

            {!this.state.changeColor && (
              <img className="logoBrand black" src={BlackLogo} alt="Logo" />
            )}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="justify-navItems">
              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                href="/"
              >
                Início
              </Nav.Link>
              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                href="/pesquisa-mercado-livre"
              >
                Pesquisa Mercado Livre
              </Nav.Link>
              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                href="/simulacao"
              >
                Simulação
              </Nav.Link>

              {/* {isAuthenticated() && (
                <Nav.Link
                  id="navItem-color"
                  style={divStyle}
                  className="hvr-underline-from-left"
                  href="/marketplace"
                >
                  Marketplace
                </Nav.Link>
              )} */}

              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                href="/mercado-livre"
              >
                Mercado Livre de Energia
              </Nav.Link>

              {/* <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                href="parceiros"
              >
                Parceiro
              </Nav.Link> */}

              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                href="/autoproducao"
              >
                Autoprodução de Energia
              </Nav.Link>

              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                href=""
                onClick={() => handleScroll()}
              >
                Contato
              </Nav.Link>

              {!isAuthenticated() && (
                <Nav.Link
                  id="navItem-color"
                  style={divStyle}
                  className="hvr-underline-from-left"
                  href="/login"
                >
                  Login
                </Nav.Link>
              )}

              {isAuthenticated() && (
                <>
                  <Nav.Link
                    className="hvr-underline-from-left"
                    id="navItem-color"
                    style={divStyle}
                    href={
                      this.state.Usertype === "CLIENTE"
                        ? "/client"
                        : this.state.Usertype === "PARCEIRO"
                        ? "/parceiro"
                        : this.state.Usertype === "ADMIN" && "/areaAdm"
                    }
                  >
                    {this.state.Usertype === "CLIENTE"
                      ? "Área do cliente"
                      : this.state.Usertype === "PARCEIRO"
                      ? "Área do Integrador"
                      : this.state.Usertype === "ADMIN" && "Área do Admin"}
                  </Nav.Link>
                  <Nav.Link
                    style={divStyle}
                    className="hvr-underline-from-left"
                    id="navItem-color"
                    onClick={this.onLogoutClick}
                  >
                    Sair
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
export default App;
